import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Box, Grid } from '@mui/material'

import {
  AppContainer,
  BackButton,
  ButtonsContainer,
  MarketAssetPrice,
  MarketAssetTitle,
  MarketStatistics,
  PriceStatistics,
  RangeButtons,
  TickerPricesGraph,
} from '../components'
import { DomainContext } from '../contexts'
import { MARKET_ASSET_QUERY, TICKER_PRICES_QUERY } from '../queries'
import { getTimeframeData, hourlyTimestamp } from '../services'

import type {
  MarketAssetData,
  MarketAssetVars,
  TickerPricesData,
  TickerPricesVars,
} from '../queries'

type Range = TickerPricesVars['range']

const graphHeight = 280

export type MarketDetailsViewProps = {
  symbol?: string
}

export const MarketDetailsView = ({ symbol }: MarketDetailsViewProps) => {
  const { quoteSymbol } = React.useContext(DomainContext)
  const stringSymbol = symbol || ''
  const [range, setRange] = React.useState<Range>('24h')

  const { loading: assetLoading, data: assetData } =
    useQuery<MarketAssetData, MarketAssetVars>(MARKET_ASSET_QUERY, {
      variables: {
        symbol: stringSymbol,
      },
    })

  const { loading: pricesLoading, data: pricesData } =
    useQuery<TickerPricesData, TickerPricesVars>(TICKER_PRICES_QUERY, {
      variables: {
        symbol: stringSymbol,
        range: range,
        endTimestamp: hourlyTimestamp(),
      },
    })

  const timeframeData = getTimeframeData(pricesData?.tickerPrices || [], stringSymbol, quoteSymbol!)
  const isLoading = assetLoading || pricesLoading
  const hasNoData = (!isLoading && timeframeData.prices.length === 0)

  return (
    <Grid
      container
      spacing={3}
    >
      <ButtonsContainer>
        <BackButton
          text='Mercado'
          containerProps={{ xs: true }}
        />
        <RangeButtons<Range>
          disabled={isLoading}
          keys={['24h', '7d', '30d', '1y', 'all']}
          range={range}
          setRange={setRange}
        />
      </ButtonsContainer>
      <MarketAssetTitle marketAsset={assetData?.marketAsset} />
      <MarketAssetPrice timeframeData={timeframeData} />
      <AppContainer sx={{ height: graphHeight, py: 1 }}>
        {(hasNoData) ? (
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            height='100%'
          >
            No hay informacion de precios para este periodo.
          </Box>
        ) : (
          <TickerPricesGraph
            currency='USD'
            isStableCoin={['USDT', 'USDC'].includes(symbol || '')}
            data={timeframeData.prices}
            ath={timeframeData.ath}
            atl={timeframeData.atl}
          />
        )}
      </AppContainer>
      <PriceStatistics
        range={range}
        timeframeData={timeframeData}
      />
      <MarketStatistics
        marketAsset={assetData?.marketAsset}
        lastPrice={timeframeData.lastPrice}
      />
    </Grid>
  )
}
