import * as React from 'react'

import { SeoHeaders } from 'shared/components'
import { PortfolioView } from 'shared/views'

import type { RouteComponentProps } from '@reach/router'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Portfolio = (props: RouteComponentProps) => {

  return (
    <React.Fragment>
      <SeoHeaders title='Portafolio' />
      <PortfolioView />
    </React.Fragment>
  )
}

export default Portfolio
