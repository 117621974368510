import * as React from 'react'

import { SeoHeaders } from 'shared/components'
import { PortfolioDetailsView } from 'shared/views'

import type { RouteComponentProps } from '@reach/router'
import type { PortfolioDetailsViewProps } from 'shared/views'

type PortfolioDetailsProps = PortfolioDetailsViewProps & RouteComponentProps

const PortfolioDetails = ({ symbol }: PortfolioDetailsProps) => (
  <React.Fragment>
    <SeoHeaders title='Portafolio' />
    <PortfolioDetailsView symbol={symbol} />
  </React.Fragment>
)

export default PortfolioDetails
