import * as React from 'react'

import { SeoHeaders } from 'shared/components'
import { PurchaseView } from 'shared/views'

import type { RouteComponentProps } from '@reach/router'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Purchase = (props: RouteComponentProps) => (
  <React.Fragment>
    <SeoHeaders title='Comprar' />
    <PurchaseView />
  </React.Fragment>
)

export default Purchase
