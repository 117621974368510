import * as React from 'react'

import { SeoHeaders } from 'shared/components'
import { MarketDetailsView } from 'shared/views'

import type { RouteComponentProps } from '@reach/router'
import type { MarketDetailsViewProps } from 'shared/views'

type MarketDetailsProps = MarketDetailsViewProps & RouteComponentProps

const MarketDetails = ({ symbol }: MarketDetailsProps) => {
  return (
    <React.Fragment>
      <SeoHeaders title='Mercado' />
      <MarketDetailsView symbol={symbol} />
    </React.Fragment>
  )
}

export default MarketDetails
