import * as React from 'react'

import { SeoHeaders } from 'shared/components'
import { SwapView } from 'shared/views'

import type { RouteComponentProps } from '@reach/router'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Swap = (props: RouteComponentProps) => (
  <React.Fragment>
    <SeoHeaders title='Convertir' />
    <SwapView />
  </React.Fragment>
)

export default Swap
