import * as React from 'react'

import { useQuery } from '@apollo/client'
import {
  Autocomplete,
  Box,
  Grid,
} from '@mui/material'

import {
  AutocompleteContainer,
  OptionCheckbox,
  OptionDisplay,
  OptionTextField,
  UserDocumentsList,
} from '../components'
import {
  USER_DOCUMENTS_QUERY,
  USER_DOCUMENT_TYPE_LABELS,
} from '../queries'

import type {
  UserDocumentsData,
  UserDocumentsVars,
} from '../queries'

const OPTIONS = Object.entries(USER_DOCUMENT_TYPE_LABELS)

export const DocumentsView = () => {
  const [documentTypes, setDocumentTypes] = React.useState<string[]>([])

  const { loading, data } =
    useQuery<UserDocumentsData, UserDocumentsVars>(USER_DOCUMENTS_QUERY)

  const userDocuments = data?.userDocuments || []

  const filteredUserDocuments = (documentTypes.length > 0)
    ? userDocuments.filter((userDocument) => documentTypes.includes(userDocument.documentType))
    : userDocuments

  return (
    <Box sx={{ maxWidth: 'md', mx: 'auto' }}>
      <Grid
        container
        spacing={3}
      >
        <AutocompleteContainer>
          <Autocomplete
            disableCloseOnSelect
            multiple
            options={OPTIONS}
            getOptionLabel={(option) => option[1]}
            onChange={(_event, newValue) => {
              setDocumentTypes(newValue.map((option) => option[0]))
            }}
            renderOption={(props, option, { selected }) => (
              <OptionDisplay {...props}>
                <OptionCheckbox checked={selected} />
                {option[1]}
              </OptionDisplay>
            )}
            renderInput={(params) => (
              <OptionTextField
                name='documentType'
                label='Filtrar por tipo de documento'
                params={params}
              />
            )}
            disablePortal
          />
        </AutocompleteContainer>
        <UserDocumentsList
          loading={loading}
          documents={filteredUserDocuments}
        />
      </Grid>
    </Box>
  )
}
