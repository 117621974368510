import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Grid } from '@mui/material'

import {
  AppContainer,
  FomoText,
  Loading,
  NotAvailable,
  SwapForm,
} from '../components'
import { MARKET_ASSETS_QUERY, SWAP_CONFIG_QUERY, USER_OPERATIONS_QUERY } from '../queries'
import { asOperationsArray, getTotalAmounts } from '../services'

import type {
  MarketAssetsData,
  MarketAssetsVars,
  SwapConfig,
  SwapConfigData,
  SwapConfigVars,
  UserOperationsData,
  UserOperationsVars,
} from '../queries'

const getSwapConfig = (configData?: SwapConfigData): SwapConfig => (
  configData?.swapConfig || { allowedPairs: [] }
)

const SwapFormWrapper = () => {
  const { data: assetsData, loading: assetsLoading } =
    useQuery<MarketAssetsData, MarketAssetsVars>(MARKET_ASSETS_QUERY)

  const { data: operationsData, loading: operationsLoading } =
    useQuery<UserOperationsData, UserOperationsVars>(USER_OPERATIONS_QUERY)

  const { data: configData, loading: configLoading } =
    useQuery<SwapConfigData, SwapConfigVars>(SWAP_CONFIG_QUERY)

  const marketAssets = assetsData?.marketAssets || []

  const symbols = marketAssets.map(({ symbol }) => symbol)
  const operations = asOperationsArray(operationsData?.userOperations)
  const totalAmounts = getTotalAmounts(symbols, operations)

  const swapConfig = getSwapConfig(configData)

  if (configLoading || assetsLoading || operationsLoading) {
    return <Loading />
  } else if (swapConfig.allowedPairs.length === 0) {
    return (
      <NotAvailable
        message='Por el momento no tenemos opciones de conversión habilitadas'
      />
    )
  } else if (Object.values(totalAmounts).every((amount) => amount <= 0)) {
    return <FomoText />
  }

  return (
    <SwapForm
      marketAssets={marketAssets}
      totalAmounts={totalAmounts}
      swapConfig={swapConfig}
    />
  )
}

export const SwapView = () => (
  <Grid
    container
    maxWidth='sm'
    mx='auto'
  >
    <AppContainer sx={{ p: 3 }}>
      <SwapFormWrapper />
    </AppContainer>
  </Grid>
)
