import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Grid } from '@mui/material'

import {
  AppContainer,
  BackButton,
  ButtonsContainer,
  EarningsBalance,
  FomoText,
  GraphUnitButtons,
  MainBalance,
  OperationsList,
  RangeButtons,
  TickerPricesGraph,
  VariationBalance,
} from '../components'
import { DomainContext } from '../contexts'
import { TICKER_PRICES_QUERY, USER_OPERATIONS_QUERY } from '../queries'
import {
  asOperationsArray,
  getSymbol,
  getTimeframeData,
  getTotalAmount,
  getUnspentEarnedAmount,
  getUserAssetAmounts,
  getUserAssetPriceData,
  hourlyTimestamp,
  useHasApyWithSymbol,
  withSymbol,
} from '../services'

import type {
  TickerPricesData,
  TickerPricesVars,
  UserOperationsData,
  UserOperationsVars,
} from '../queries'

type Range = TickerPricesVars['range']

const graphHeight = 280

export type PortfolioDetailsViewProps = {
  symbol?: string
}

export const PortfolioDetailsView = ({ symbol }: PortfolioDetailsViewProps) => {
  const { quoteSymbol, conversionCurrency } = React.useContext(DomainContext)
  const originalSymbol = symbol || ''
  const stringSymbol = getSymbol(symbol)
  const endTimestamp = hourlyTimestamp()
  const [graphUnit, setGraphUnit] = React.useState<string>(conversionCurrency!)
  const [range, setRange] = React.useState<Range>('24h')

  const { loading: hasApyLoading, hasApy } = useHasApyWithSymbol(originalSymbol)

  const { loading: pricesLoading, data: pricesData } =
    useQuery<TickerPricesData, TickerPricesVars>(TICKER_PRICES_QUERY, {
      variables: {
        symbol: stringSymbol,
        range,
        endTimestamp,
      },
    })

  const { loading: operationsLoading, data: operationsData } =
    useQuery<UserOperationsData, UserOperationsVars>(USER_OPERATIONS_QUERY)

  const tickerPrices = pricesData ? pricesData.tickerPrices : []

  const assetOperations = withSymbol(
    asOperationsArray(operationsData?.userOperations), originalSymbol,
  )
  const hasNoOperations = (!operationsLoading && assetOperations.length === 0)

  const timeframeData = getTimeframeData(tickerPrices, stringSymbol, quoteSymbol!)
  const assetUsdPrices = timeframeData.prices
  const assetUsdPrice = timeframeData.lastPrice

  const amounts = getUserAssetAmounts(stringSymbol, assetOperations, assetUsdPrices)
  const totalAmount = getTotalAmount(stringSymbol, assetOperations)
  const earnedAmount = getUnspentEarnedAmount(stringSymbol, assetOperations)

  const { assetPrices, ath, atl, variation } =
    getUserAssetPriceData(amounts, assetUsdPrices, graphUnit === conversionCurrency!)

  return (
    <Grid
      container
      spacing={3}
    >
      <MainBalance
        loading={pricesLoading || operationsLoading}
        currency={graphUnit}
        assetAmount={totalAmount}
        assetUsdPrice={assetUsdPrice}
      />
      {hasApy ? (
        <EarningsBalance
          loading={hasApyLoading || pricesLoading || operationsLoading}
          currency={graphUnit}
          assetAmount={earnedAmount}
          assetUsdPrice={assetUsdPrice}
        />
      ) : (
        <VariationBalance
          loading={hasApyLoading || pricesLoading || operationsLoading}
          currency={graphUnit}
          range={range}
          variation={variation}
        />
      )}
      <ButtonsContainer>
        <BackButton
          text='Portafolio'
          containerProps={{ xs: true }}
        />
        <GraphUnitButtons
          disabled={pricesLoading}
          keys={[conversionCurrency!, stringSymbol]}
          graphUnit={graphUnit}
          setGraphUnit={setGraphUnit}
        />
        <RangeButtons<Range>
          disabled={pricesLoading || hasNoOperations}
          keys={['24h', '7d', '30d', '1y', 'all']}
          range={range}
          setRange={setRange}
        />
      </ButtonsContainer>
      <AppContainer sx={{ height: graphHeight, py: 1 }}>
        {hasNoOperations ? (
          <FomoText symbol={symbol} />
        ) : (
          <TickerPricesGraph
            currency={graphUnit}
            data={assetPrices}
            ath={ath}
            atl={atl}
          />
        )}
      </AppContainer>
      <OperationsList
        loading={pricesLoading || operationsLoading}
        operations={assetOperations}
      />
    </Grid>
  )
}
