import * as React from 'react'

import { SeoHeaders } from 'shared/components'
import { getQueryStringValue } from 'shared/services'
import { KoyweTracingView } from 'shared/views'

import type { RouteComponentProps } from '@reach/router'

const KoyweTracing = ({ location }: RouteComponentProps) => {
  const successStr = getQueryStringValue(location?.search || '', 'success', '')
  const successBool = successStr === 'true'

  return (
    <React.Fragment>
      <SeoHeaders title='Comprar' />
      <KoyweTracingView success={successBool} />
    </React.Fragment>
  )
}

export default KoyweTracing
