import * as React from 'react'

import { SeoHeaders } from 'shared/components'
import { WithdrawBlockchainView } from 'shared/views'

import type { RouteComponentProps } from '@reach/router'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const WithdrawBlockchain = (props: RouteComponentProps) => {

  return (
    <React.Fragment>
      <SeoHeaders title='Retirar al blockchain' />
      <WithdrawBlockchainView />
    </React.Fragment>
  )
}

export default WithdrawBlockchain
