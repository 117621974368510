import * as React from 'react'

import { SeoHeaders } from 'shared/components'
import { WithdrawBankView } from 'shared/views'

import type { RouteComponentProps } from '@reach/router'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const WithdrawBank = (props: RouteComponentProps) => (
  <React.Fragment>
    <SeoHeaders title='Retirar a cuenta bancaria' />
    <WithdrawBankView />
  </React.Fragment>
)

export default WithdrawBank
