import * as React from 'react'

import { AccountBalance, AccountBalanceWallet } from '@mui/icons-material'
import { Button, Grid, Stack, Typography } from '@mui/material'
import { Link } from '@reach/router'

import { AppContainer } from '../components'

export const WithdrawView = () => (
  <Grid
    container
    maxWidth='sm'
    mx='auto'
  >
    <AppContainer sx={{ p: 3 }}>
      <Stack
        alignItems='center'
        justifyContent='center'
        spacing={2}
        mx='auto'
        maxWidth='420px'
      >
        <Typography
          variant='h6'
          component='p'
          textAlign='center'
          pb={1}
        >
          Retirar criptomonedas
        </Typography>
        <Button
          component={Link}
          to='./blockchain'
          startIcon={<AccountBalanceWallet />}
          variant='contained'
          size='large'
          fullWidth
        >
          Hacia billetera blockchain
        </Button>
        <Button
          component={Link}
          to='./bank'
          startIcon={<AccountBalance />}
          variant='contained'
          size='large'
          fullWidth
        >
          Hacia cuenta bancaria
        </Button>
      </Stack>
    </AppContainer>
  </Grid>
)
