import * as React from 'react'

import { SeoHeaders } from 'shared/components'
import { MarketView } from 'shared/views'

import type { RouteComponentProps } from '@reach/router'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Market = (props: RouteComponentProps) => {

  return (
    <React.Fragment>
      <SeoHeaders title='Mercado' />
      <MarketView />
    </React.Fragment>
  )
}

export default Market
