import * as React from 'react'

import { useQuery } from '@apollo/client'

import { FomoText, PortfolioDisplay } from '../components'
import {
  ALL_TICKER_PRICES_QUERY,
  BASE_APYS_QUERY,
  MARKET_ASSETS_QUERY,
  USER_OPERATIONS_QUERY,
} from '../queries'
import { asOperationsArray, hourlyTimestamp } from '../services'

import type {
  AllTickerPricesData,
  AllTickerPricesVars,
  BaseApysData,
  BaseApysVars,
  MarketAssetsData,
  MarketAssetsVars,
  UserOperationsData,
  UserOperationsVars,
} from '../queries'

type Range = AllTickerPricesVars['range']

export const PortfolioView = () => {
  const [range, setRange] = React.useState<Range>('24h')

  const { loading: assetsLoading, data: assetsData } =
    useQuery<MarketAssetsData, MarketAssetsVars>(MARKET_ASSETS_QUERY)

  const { loading: pricesLoading, data: pricesData } =
    useQuery<AllTickerPricesData, AllTickerPricesVars>(ALL_TICKER_PRICES_QUERY, {
      variables: {
        range,
        endTimestamp: hourlyTimestamp(),
      },
    })

  const { loading: baseApysLoading, data: baseApysData } =
    useQuery<BaseApysData, BaseApysVars>(BASE_APYS_QUERY)

  const { loading: operationsLoading, data: operationsData } =
    useQuery<UserOperationsData, UserOperationsVars>(USER_OPERATIONS_QUERY)

  const operations = asOperationsArray(operationsData?.userOperations)

  return (
    <PortfolioDisplay
      loading={assetsLoading || pricesLoading || baseApysLoading || operationsLoading}
      assetsData={assetsData}
      pricesData={pricesData}
      baseApysData={baseApysData}
      operations={operations}
      range={range}
      setRange={setRange}
      fomoText={<FomoText />}
    />
  )
}
