import * as React from 'react'

import { SeoHeaders } from 'shared/components'
import { DepositView } from 'shared/views'

import type { RouteComponentProps } from '@reach/router'
import type { DepositViewProps } from 'shared/views'

type DepositProps = DepositViewProps & RouteComponentProps

const Deposit = ({ symbol }: DepositProps) => (
  <React.Fragment>
    <SeoHeaders title='Depositar' />
    <DepositView symbol={symbol} />
  </React.Fragment>
)

export default Deposit
