import * as React from 'react'

import { useMutation, useQuery } from '@apollo/client'
import {
  Autocomplete,
  Box,
  Button,
  Collapse,
  Grid,
  Stack,
  Typography,
} from '@mui/material'

import {
  AddressDisplay,
  AppContainer,
  AssetBadge,
  AutocompleteContainer,
  ButtonContainer,
  ButtonsContainer,
  Dialog,
  MarketAssetAdornment,
  OptionDisplay,
  OptionTextField,
} from '../components'
import {
  CREATE_DEPOSIT_ADDRESS_MUTATION,
  DEPOSIT_ADDRESSES_QUERY, MARKET_ASSETS_QUERY,
} from '../queries'
import { translateError } from '../services'

import type {
  CreateDepositAddressData,
  CreateDepositAddressVars,
  DepositAddress,
  DepositAddressesData,
  DepositAddressesVars,
  MarketAsset,
  MarketAssetsData,
  MarketAssetsVars,
} from '../queries'

const AddressGenerator = ({ blockchain }: { blockchain: string }) => {
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [dialogContentText, setDialogContentText] = React.useState<React.ReactNode>()

  const [generateDepositAddress, { loading }] =
    useMutation<CreateDepositAddressData, CreateDepositAddressVars>(CREATE_DEPOSIT_ADDRESS_MUTATION, {
      errorPolicy: 'all',
      refetchQueries: [
        DEPOSIT_ADDRESSES_QUERY,
      ],
      variables: {
        blockchain: blockchain,
      },
    })

  const handleClick = async () => {
    const response = await generateDepositAddress()

    if (response.data?.createDepositAddress) {
      /* TODO: revert this response once the service works again */
      setDialogContentText('Tu dirección será asignada manualmente a la brevedad.')
    } else {
      setDialogContentText(translateError(response))
    }

    openDialog()
  }

  const openDialog = () => setDialogOpen(true)

  const closeDialog = () => setDialogOpen(false)

  return (
    <React.Fragment>
      <Typography textAlign='center'>
        Si es la primera vez que depositas a través de la&nbsp;
        <span style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>
          {blockchain} network
        </span> deberás generar una dirección de depósito.
      </Typography>
      <Button
        disabled={loading}
        onClick={handleClick}
        sx={{ fontWeight: 500 }}
        variant='contained'
        size='large'
      >
        {loading ? 'GENERANDO...' : 'GENERAR DIRECCIÓN'}
      </Button>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        title='Generar dirección'
        contentText={dialogContentText}
      >
        <ButtonsContainer mt={2}>
          <ButtonContainer xs={12}>
            <Button
              fullWidth
              onClick={closeDialog}
              variant='contained'
              size='large'
            >
              Entendido
            </Button>
          </ButtonContainer>
        </ButtonsContainer>
      </Dialog>
    </React.Fragment>
  )
}

type AddressInfoProps = {
  marketAsset: MarketAsset | null
  blockchain: string | null
  depositAddresses: DepositAddress[]
}

const AddressInfo = ({ marketAsset, blockchain, depositAddresses }: AddressInfoProps) => {
  const shouldDisplay = (marketAsset && blockchain)
  const existingAddress = shouldDisplay
    ? depositAddresses.find((address) => address.blockchain === blockchain)
    : null

  return (
    <AppContainer sx={shouldDisplay ? { px: 3, py: 2 } : { p: 0 }}>
      <Collapse in={Boolean(shouldDisplay)}>
        {shouldDisplay && (
          <Stack
            spacing={2}
            pb={1}
          >
            <AssetBadge
              animated
              symbol={marketAsset.symbol}
              height={100}
            />
            {existingAddress ? (
              <AddressDisplay
                blockchain={blockchain}
                address={existingAddress}
              />
            ) : (
              <AddressGenerator blockchain={blockchain} />
            )}
          </Stack>
        )}
      </Collapse>
    </AppContainer>
  )
}

export type DepositViewProps = {
  symbol?: string
}

export const DepositView = ({
  symbol,
}: DepositViewProps) => {
  const [defaultSymbol, setDefaultSymbol] = React.useState<string | null>(symbol || null)
  const [marketAsset, setMarketAsset] = React.useState<MarketAsset | null>(null)
  const [blockchain, setBlockchain] = React.useState<string | null>(null)

  const { loading: assetsLoading, data: assetsData } =
    useQuery<MarketAssetsData, MarketAssetsVars>(MARKET_ASSETS_QUERY)

  const { loading: addressesLoading, data: addressesData } =
    useQuery<DepositAddressesData, DepositAddressesVars>(DEPOSIT_ADDRESSES_QUERY)

  const marketAssets = React.useMemo(() => (
    assetsData?.marketAssets.filter((asset) => asset.blockchains.length > 0) || []
  ), [assetsData])

  const depositAddresses = React.useMemo(() => (
    addressesData?.depositAddresses || []
  ), [addressesData])

  const selectMarketAsset = (newValue: MarketAsset | null) => {
    setMarketAsset(newValue)
    const availableBlockchains = newValue?.blockchains

    if (availableBlockchains?.length == 1) {
      setBlockchain(availableBlockchains[0])
    } else {
      setBlockchain(null)
    }
  }

  React.useEffect(() => {
    if (marketAsset || !defaultSymbol) {
      return
    }

    selectMarketAsset(marketAssets.find((asset) => asset.symbol === defaultSymbol) || null)
    setDefaultSymbol(null)
  }, [defaultSymbol, marketAsset, marketAssets])

  return (
    <Box sx={{ maxWidth: 'md', mx: 'auto' }}>
      <Grid
        container
        spacing={3}
      >
        <AutocompleteContainer>
          <Autocomplete
            disabled={assetsLoading || addressesLoading}
            options={marketAssets.filter((asset) => asset.blockchains.length > 0)}
            getOptionLabel={(option) => option.name}
            value={marketAsset}
            onChange={(_event, newValue) => selectMarketAsset(newValue)}
            renderOption={(props, option) => (
              <OptionDisplay {...props}>
                <AssetBadge
                  symbol={option.symbol}
                  height={20}
                />
                {option.name}
              </OptionDisplay>
            )}
            renderInput={(params) => (
              <OptionTextField
                startAdornment={<MarketAssetAdornment symbol={marketAsset?.symbol} />}
                name='marketAsset'
                label='Selecciona un activo'
                params={params}
              />
            )}
            disablePortal
          />
        </AutocompleteContainer>
        <AutocompleteContainer>
          <Autocomplete
            disabled={!marketAsset || marketAsset.blockchains.length < 2}
            options={marketAsset?.blockchains || []}
            value={blockchain}
            onChange={(_event, newValue) => setBlockchain(newValue)}
            renderInput={(params) => (
              <OptionTextField
                name='networkName'
                label='Selecciona una red'
                params={params}
              />
            )}
            disablePortal
          />
        </AutocompleteContainer>
        <AddressInfo
          marketAsset={marketAsset}
          blockchain={blockchain}
          depositAddresses={depositAddresses}
        />
      </Grid>
    </Box>
  )
}
