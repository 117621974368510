import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Box } from '@mui/material'

import {
  AppContainer,
  Loading,
  NotAvailable,
  WithdrawBankForm,
} from '../components'
import {
  ALL_TICKER_PRICES_QUERY,
  BANK_CONFIG_QUERY,
  MARKET_ASSETS_QUERY,
  USER_OPERATIONS_QUERY,
} from '../queries'
import { asOperationsArray, hourlyTimestamp } from '../services'

import type {
  AllTickerPricesData,
  AllTickerPricesVars,
  BankConfig,
  BankConfigData,
  BankConfigVars,
  MarketAssetsData,
  MarketAssetsVars,
  UserOperationsData,
  UserOperationsVars,
} from '../queries'

const NULL_BANK_CONFIG: BankConfig = {
  bankCodes: [],
  userBankAccounts: [],
  userFiatCurrency: '',
  userLegalDocumentNumber: '',
  userLegalName: '',
}

const BankWithdrawalFormWrapper = () => {
  const { loading: configLoading, data: configData } =
    useQuery<BankConfigData, BankConfigVars>(BANK_CONFIG_QUERY)

  const { loading: assetsLoading, data: assetsData } =
    useQuery<MarketAssetsData, MarketAssetsVars>(MARKET_ASSETS_QUERY)

  const { loading: pricesLoading, data: pricesData } =
    useQuery<AllTickerPricesData, AllTickerPricesVars>(ALL_TICKER_PRICES_QUERY, {
      variables: {
        range: '24h',
        endTimestamp: hourlyTimestamp(),
      },
    })

  const { loading: operationsLoading, data: operationsData } =
    useQuery<UserOperationsData, UserOperationsVars>(USER_OPERATIONS_QUERY)

  const bankConfig = configData?.bankConfig || NULL_BANK_CONFIG
  const marketAssets = assetsData?.marketAssets || []
  const tickerPrices = pricesData ? pricesData.allTickerPrices : []
  const operations = asOperationsArray(operationsData?.userOperations)

  if (configLoading || assetsLoading || pricesLoading || operationsLoading) {
    return <Loading />
  } else if (bankConfig.bankCodes.length === 0) {
    return (
      <NotAvailable
        message='Por el momento no tenemos opciones de retiro bancario disponibles en tu país'
      />
    )
  }

  return (
    <WithdrawBankForm
      bankConfig={bankConfig}
      marketAssets={marketAssets}
      tickerPrices={tickerPrices}
      operations={operations}
    />
  )
}

export const WithdrawBankView = () => (
  <Box sx={{ maxWidth: 'sm', mx: 'auto' }}>
    <AppContainer sx={{ p: 3 }}>
      <BankWithdrawalFormWrapper />
    </AppContainer>
  </Box>
)
