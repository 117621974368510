import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Grid } from '@mui/material'

import {
  AppContainer,
  Loading,
  NotAvailable,
  PurchaseForm,
} from '../components'
import { PURCHASE_CONFIG_QUERY } from '../queries'

import type {
  PurchaseConfig,
  PurchaseConfigData,
  PurchaseConfigVars,
} from '../queries'

const NULL_PURCHASE_CONFIG: PurchaseConfig = {
  userFiatCurrency: '',
  purchaseMethods: [],
}

const PurchaseFormWrapper = () => {
  const { loading, data } =
    useQuery<PurchaseConfigData, PurchaseConfigVars>(PURCHASE_CONFIG_QUERY)

  const purchaseConfig = data?.purchaseConfig || NULL_PURCHASE_CONFIG
  const { userFiatCurrency, purchaseMethods } = purchaseConfig

  if (loading) {
    return <Loading />
  } else if (!userFiatCurrency || purchaseMethods.length === 0) {
    return (
      <NotAvailable
        message='Por el momento no tenemos opciones de compra disponibles en tu país'
      />
    )
  }

  return (
    <PurchaseForm purchaseConfig={purchaseConfig} />
  )
}

export const PurchaseView = () => (
  <Grid
    container
    maxWidth='sm'
    mx='auto'
  >
    <AppContainer sx={{ p: 3 }}>
      <PurchaseFormWrapper />
    </AppContainer>
  </Grid>
)
